import React from "react"
import Header from "../components/header/header"
import { Link } from "gatsby"
import "../../styles/gallery.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Fade from "react-reveal/Fade"

const Gallery = ({ data }) => {
  return (
    <Layout>
      <Header />
      <SEO
        title="Gallery | Browse Our Event & Wedding Design"
        description="YESInspired Events is a local wedding planning and design company offering custom, elegant, and beautiful wedding and event designs in Toronto, Ontario, Canada. We are a modern day event planning and design company, creating weddings that inspire all of the senses."
      />
      <div className="gallery-page">
        <div className="gallery-page-section-text-wrapper">
          <Fade>
            <h2 className="gallery-page-sub-heading">Latest events</h2>
            <h1 className="gallery-page-heading">Our Gallery</h1>
            <p className="gallery-page-body">
              We design to be timeless and elegant. Each wedding is unique, as
              is each guest. Great event design should never overshadow the
              guest of honor, but should complement the moments of your day
              while directing attention and adding fun, elegance or glamour when
              needed. We take pride in making lives happy and memorable one
              event at a time and promise to offer you an experience like no
              other.
            </p>
          </Fade>
        </div>
      </div>
      <div className="gallery-preview-list">
        <Fade>
          <Link to="/gallery/mint-studios-photoshoot">
            <div className="gallery-preview-component">
              <Img
                className="gallery-preview-component-image"
                fluid={data.galleryPreview8.childImageSharp.fluid}
              />
              <h3 className="gallery-preview-component-heading">
                Mint Studios Photoshoot
              </h3>
            </div>
          </Link>
          <Link to="/gallery/sarah-and-ali">
            <div className="gallery-preview-component">
              <Img
                className="gallery-preview-component-image"
                fluid={data.galleryPreview7.childImageSharp.fluid}
              />
              <h3 className="gallery-preview-component-heading">Sarah & Ali</h3>
            </div>
          </Link>
          <Link to="/gallery/studio-89-photoshoot">
            <div className="gallery-preview-component">
              <Img
                className="gallery-preview-component-image"
                fluid={data.galleryPreview5.childImageSharp.fluid}
              />
              <h3 className="gallery-preview-component-heading">
                Studio 89 Branding Shoot
              </h3>
            </div>
          </Link>
          <Link to="/gallery/manija-and-abdulhaq">
            <div className="gallery-preview-component">
              <Img
                className="gallery-preview-component-image"
                fluid={data.galleryPreview2.childImageSharp.fluid}
              />
              <h3 className="gallery-preview-component-heading">
                Manija & Abdulhaq
              </h3>
            </div>
          </Link>
          <Link to="/gallery/shangri-la-photoshoot">
            <div className="gallery-preview-component">
              <Img
                className="gallery-preview-component-image"
                fluid={data.galleryPreview3.childImageSharp.fluid}
              />
              <h3 className="gallery-preview-component-heading">
                Dance of the Sugarplum Fairy Style Shoot
              </h3>
            </div>
          </Link>
          <Link to="/gallery/jessica-and-christian">
            <div className="gallery-preview-component">
              <Img
                className="gallery-preview-component-image"
                fluid={data.galleryPreview.childImageSharp.fluid}
              />
              <h3 className="gallery-preview-component-heading">
                Jessica & Christian
              </h3>
            </div>
          </Link>
          <Link to="/gallery/fall-photoshoot">
            <div className="gallery-preview-component">
              <Img
                className="gallery-preview-component-image"
                fluid={data.galleryPreview6.childImageSharp.fluid}
              />
              <h3 className="gallery-preview-component-heading">
                Thanksgiving Lunch
              </h3>
            </div>
          </Link>
          <Link to="/gallery/graduation-party">
            <div className="gallery-preview-component">
              <Img
                className="gallery-preview-component-image"
                fluid={data.galleryPreview4.childImageSharp.fluid}
              />
              <h3 className="gallery-preview-component-heading">
                Graduation Party
              </h3>
            </div>
          </Link>
        </Fade>
      </div>
    </Layout>
  )
}

export default Gallery

export const galleryQuerry = graphql`
  query {
    galleryPreview: file(relativePath: { eq: "gallery-preview.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    galleryPreview2: file(relativePath: { eq: "gallery-preview-2.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    galleryPreview3: file(relativePath: { eq: "gallery-preview-3.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    galleryPreview4: file(relativePath: { eq: "gallery-preview-4.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    galleryPreview5: file(relativePath: { eq: "gallery-preview-5.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    galleryPreview6: file(relativePath: { eq: "gallery-preview-6.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    galleryPreview7: file(relativePath: { eq: "gallery-preview-7.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    galleryPreview8: file(relativePath: { eq: "gallery-preview-8.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
